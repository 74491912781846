<template>
    <div class='page-index mind middle'>
        <div class="bcg-box">
            <bcg>
                <template v-slot:items>
                    <div class="index-module-item" 
                    @mousedown="$event.currentTarget.classList.add(!v.disabled ? 'button-scale-down' : 'd')"
                    @touchstart="$event.currentTarget.classList.add(!v.disabled ? 'button-scale-down' : 'd')"
                    @touchleave="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                    @mouseleave="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                    @touchend="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                    @mouseup="$event.currentTarget.classList.remove(!v.disabled ? 'button-scale-down' : 'd')"
                    v-for="(v, i) in moduleList" :key="i" :class="[v.class, {'disabled': v.disabled}]" @click="middleGotoPage(v)">
                        <div class="title"></div>
                    </div>
                </template>
            </bcg>
        </div>
        <div class="user-box">
            <div class="user-box-fx">
                <userInfo></userInfo>
                <!-- <div class="mall-enter scale-button" v-show="!isMallOverdue" @click="_routeHand.goto({name: 'mall'})"></div> -->
                <!-- <trialCom ref="trialCom" v-show="showActivateFlag"></trialCom> -->
            </div>
        </div>
        <confirmPro ref="confirmPro" style="z-index: 300;">
            <div class="pro-close-box scale-button" slot="close" @click="quitApp"></div>
        </confirmPro>
    </div>
</template>
<script>
    import com from '@/pages/mind/views/index/index.vue'
    import bcg from './components/bcg.vue'

    export default {
        name: '',
        extends: com,
        props: {
        },
        data() {
            return {
                moduleList: [
                    {class: 'm4', routeName: 'task'}, // 练功房
                    {class: 'm5', routeName: 'cabin'}, // 名师讲堂
                    {class: 'm6', routeName: 'palace', disabled: false}, // 知识海岛
                    {class: 'm7', routeName: 'game', disabled: true}, // AI自习室
                    {class: 'm8', routeName: 'center', disabled: false}, // 任务中心
                ],
            };
        },
        methods: {
            mindPageInit(){},
            async userAccount(){
                let res = await this.api.userAccount()
                if(!res) return
            },
            middleGotoPage(val){
                let {routeName, disabled} = val || {}
                if(routeName == 'center' && !disabled) return this._common.taskCenter()
                this.gotoPage(val)
            },
        },
        created() {
        },
        components: {
            bcg,
        },
        computed: {
        },
        watch: {},
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .page-index.mind.middle{
        .user-box{
            padding-right: 0px;
            .user-box-fx{
                padding-right: 5px;
            }
        }
    }
</style>