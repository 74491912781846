<template>
    <div class='com-index-bcg middle'>
        <component-bcg url="index/bcg.png"></component-bcg>
        <div class="g-bcg" >
            <div class="bcg_all">
                <div class="bcg_all_bottom"></div>
                <div class="desk_bcg">
                    <div class="desk-shadow"></div>
                    <div class="book_1"></div>
                    <div class="book_2"></div>
                    <div class="path"></div>
                    <div class="book_3"></div>
                </div>
                <div class="desk">
                    <div class="board-shadow">
                        <div class="board">
                            <div class="board_1"></div>
                            <div class="board_2"></div>
                            <div class="board_3"></div>
                            <div class="board_4"></div>
                            <div class="board_5"></div>
                            <div class="board_6"></div>
                            <div class="board_7"></div>
                            <div class="board_8"></div>
                            <div class="board_9"></div>
                            <div class="board_10"></div>
                            <div class="items-box">
                                <slot name="items"></slot>
                                <div class="trees"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="light"></div>
            </div>
        </div>
        
    </div>
</template>

<script>
    import com from '@/views/index/bcg.vue'
    export default {
        name: '',
        props: {},
        extends: com,
        data() {
            return {
            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-index-bcg.middle {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .items-box {
            z-index: 1;
            height: 375px !important;
        }

        .g-bcg,
        .items-box {
            position: absolute;
            left: 10px;
            bottom: -32px;
            right: 0;
            margin: 0 auto;
            
            .trees{
                .element-position(581px, 81px, 40px, 216px);
                .background-img-max(url('@{assetsUrlMiddle}/img/home/b9.png'));
                pointer-events: none;
            }
        }
        .m4 {
            .element-position(109px, 201px, 68px, 96px);
            .background-img-max(url('@{assetsUrlMiddle}/img/home/b4.png'));
            .title{
                .element-position(54px, 31px, @right: 13px, @bottom: 36px);
                .background-img-max(url('@{assetsUrlMiddle}/img/home/b4_1.png'));
            }
        }
        .m5 {
            .element-position(157px, 159px, 155px, 69px);
            .background-img-max(url('@{assetsUrlMiddle}/img/home/b5.png'));
            .title{
                .element-position(59px, 28px, @right: 20px, @bottom: 44px);
                .background-img-max(url('@{assetsUrlMiddle}/img/home/b5_1.png'));
            }
        }

        .m6 {
            .element-position(126px, 185px, 326px, 30px);
            .background-img-max(url('@{assetsUrlMiddle}/img/home/b6.png'));
            .title{
                .element-position(63px, 16px, @right: 32px, @bottom: 58px);
                .background-img-max(url('@{assetsUrlMiddle}/img/home/b6_1.png'));
            }
        }

        .m7 {
            .element-position(165px, 131px, 438px, 152px);
            .background-img-max(url('@{assetsUrlMiddle}/img/home/b7.png'));
            .title{
                .element-position(53px, 42px, @left: 6px, @bottom: 12px);
                .background-img-max(url('@{assetsUrlMiddle}/img/home/b7_1.png'));
            }
        }
        .m8 {
            .element-position(162px, 103px, 264px, 192px);
            .background-img-max(url('@{assetsUrlMiddle}/img/home/b8.png'));
            .title{
                .element-position(51px, 14px, 56px, 25px);
                .background-img-max(url('@{assetsUrlMiddle}/img/home/b8_1.png'));
            }
        }


        .bcg_all {
            width: 100vw;
            height: 100vw;
            position: fixed;
            top: 0;
            left: 0;
            background: none;
            .bcg_all_bottom{
                width: 100vw;
                height: 100vw;
                position: absolute;
                left: 0;
                top: -5vw;
                .background-img-max(url('@{assetsUrlMiddle}/img/home/b0.png'));
            }
            .desk_bcg{
                .background-img-max(url('@{assetsUrlMiddle}/img/home/b1.png'));
                width: 100vw;
                height: 270px;
                position: fixed;
                left: 0;
                bottom: -15px;
                .book_1{
                    .element-position(87px, 85px, 0px, 8px);
                    .background-img-max(url('@{assetsUrlMiddle}/img/home/b24.png'));
                }
                .book_2{
                    .element-position(89px, 86px, 0px, 8px);
                    .background-img-max(url('@{assetsUrlMiddle}/img/home/b25.png'));
                }
                .book_3{
                    .element-position(84px, 108px, -6px, -31px);
                    .background-img-max(url('@{assetsUrlMiddle}/img/home/b26.png'));
                }
                .desk-shadow{
                    .element-position(453px, 271px, @right: 0px, @bottom: -7px);
                    .background-img-max(url('@{assetsUrlMiddle}/img/home/b23.png'));
                }
                .path{
                    .background-img-max(url('@{assetsUrlMiddle}/img/home/b11.png'));
                    width: 130%;
                    height: 110%;
                    position: absolute;
                    top: 0;
                    left: -15%;
                    margin: 0 auto;
                }
            }
            .desk{
                width: 100vw;
                height: 297px;
                position: fixed;
                left: 0;
                bottom: 0;
                margin: auto;
                .board-shadow{
                    .element-position(715px, 265px, @left: -22px, @bottom: -6px);
                    right: 0;
                    margin: auto;
                    .background-img-max(url('@{assetsUrlMiddle}/img/home/b12.png'));
                    .board{
                        .element-position(692px, 197px, @left: 12px, @bottom: 38px);
                        .background-img-max(url('@{assetsUrlMiddle}/img/home/b13.png'));
                        .board_1{
                            .element-position(660px, 170px, @left: 15px, @bottom: 0);
                            .background-img-max(url('@{assetsUrlMiddle}/img/home/b14.png'));
                        }
                        .board_2{
                            .element-position(644px, 178px, @left: 24px, @bottom: 21px);
                            .background-img-max(url('@{assetsUrlMiddle}/img/home/b15.png'));
                        }
                        .board_3{
                            .element-position(644px, 179px, @left: 24px, @bottom: 20px);
                            .background-img-max(url('@{assetsUrlMiddle}/img/home/b16.png'));
                        }
                        .board_4{
                            .element-position(588px, 171px, @left: 53px, @bottom: 33px);
                            .background-img-max(url('@{assetsUrlMiddle}/img/home/b17.png'));
                        }
                        .board_5{
                            .element-position(588px, 171px, @left: 53px, @bottom: 33px);
                            .background-img-max(url('@{assetsUrlMiddle}/img/home/b18.png'));
                        }
                        .board_6{
                            .element-position(588px, 168px, @left: 53px, @bottom: 36px);
                            .background-img-max(url('@{assetsUrlMiddle}/img/home/b19.png'));
                        }
                        .board_7{
                            .element-position(585px, 177px, @left: 56px, @bottom: 27px);
                            .background-img-max(url('@{assetsUrlMiddle}/img/home/b20.png'));
                        }
                        .board_8{
                            .element-position(156px, 82px, @left: 6px, @bottom: -32px);
                            .background-img-max(url('@{assetsUrlMiddle}/img/home/b21.png'));
                        }
                        .board_9{
                            .element-position(61px, 113px, @right: 23px, @bottom: -28px);
                            .background-img-max(url('@{assetsUrlMiddle}/img/home/b22.png'));
                        }
                        .board_10{
                            .element-position(97px, 55px, @right: 137px, @top: -35px);
                            .background-img-max(url('@{assetsUrlMiddle}/img/home/b27.png'));
                        }
                    }
                }
            }
            .light{
                .element-position(289px, 259px, @top: 0, @right: 4px);
                .background-img-max(url('@{assetsUrlMiddle}/img/home/b3.png'));
                pointer-events: none;
            }
        }

        .index-module-item.disabled {
            filter: grayscale(1);
        }
    }
</style>