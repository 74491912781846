import commonApi from '../../_EXT_API'
import { singleProxy } from '@/global/js/exportEnter'

class mall extends commonApi {
    constructor(opt) {
        opt = opt || {}
        let localKey = 'mall'
        let name = 'mall'
        opt = { localKey, name }
        super(opt)
    }

    getGoodsList(opt) {
        opt = opt || {}
        let options = {
            method: 'get',
            data: {},
        }
        return this.requestAction('getGoodsList', options)
    }

    postPurchased(opt) {
        opt = opt || {}
        let id = opt.id
        let options = {
            method: 'post',
            id,
            data: {
                ...opt
            },
        }
        return this.requestAction('postPurchased', options)
    }
    
    async purchasedRecord(opt) {
        opt = opt || {}
        let { user_code } = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        let options = {
            method: "get",
            ...opt,
            data: {
                ...opt
            }
        }
        return await this.requestAction('purchasedRecord', options)
    }

    async mallOverdue(opt) {
        opt = opt || {}
        let { user_code } = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt.user_code = user_code
        let options = {
            method: "get",
            ...opt,
            data: {
                ...opt
            }
        }
        return await this.requestAction('mallOverdue', options)
    }

    async mallStatus(opt) {
        opt = opt || {}
        let { user_code } = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        let options = {
            method: "get",
            ...opt,
            fullData: true,
            data: {
                ...opt
            }
        }
        let res = await this.requestAction('mallStatus', options)
        let {code} = res || {}
        return code == 0 ? true : false
    }
    
    async exchangeRecord(opt) {
        opt = opt || {}
        let { user_code } = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        let options = {
            method: "get",
            ...opt,
            data: {
                ...opt
            }
        }
        return await this.requestAction('exchangeRecord', options)
    }

    async checkRedeemCode(opt){
        opt = opt || {}
        let { user_code } = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        let options = {
            method: "get",
            ...opt,
            data: {
                ...opt
            }
        }
        let res = await this.requestAction('checkRedeemCode', options)
        return res
    }
    
    async postExchange(opt){
        opt = opt || {}
        let { user_code } = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        let options = {
            method: "post",
            ...opt,
            data: {
                ...opt
            }
        }
        return await this.requestAction('postExchange', options)
    }

    async postDashboardClick(opt){ 
        /**
         *  seconds: 停留时长，秒
            content_id: 商品id
            click_type: 
                商城：1:商城入口 2:兑换 3:说明 4:如何获取兑换码 5:兑换详情
                服装：
                    6:三套服装的点击量 
                    7:每套服装点击量（保存）
                    8:右上角获取兑换码点击量
                    *记录服装预览停留时间click_type=dressId, content_id=秒（特殊处理）
                学霸殿堂:
                    9:点击学霸殿堂的人数
                    10:点击实战岛总览图入口的人数
                    11:点击思维岛总览图入口的人数
                    12:实战岛总览图中点击各岛的次数/思维岛总览图中点击各岛的次数,content_id传小岛id
                    13:主界面停留时长
                    14:思维岛停留时长
                    15:实战岛停留时长
                    16:每个小岛选课界面停留时长
                    17:实战岛总览图停留时长
                    18:思维岛总预图停留时长
            
         */
        opt = opt || {}
        let { user_code } = opt
        user_code = user_code || this._appData.getItem('usercode', 'app')
        opt.user_code = user_code
        let options = {
            method: "post",
            ...opt,
            data: {
                ...opt
            }
        }
        return await this.requestAction('postDashboardClick', options)
    }
}

export default singleProxy(mall)